<template>
  <div class="bt-profile pa-5">
    <h6 class="text-h5 font-weight-bold indigo--text text--lighten-1 d-flex align-center">
      <div class="rounded-pill indigo lighten-1 me-2 px-2 d-flex align-center justify-center py-2 elevation-3">
        <v-icon small color="white">mdi-account</v-icon>
      </div>
      Profile
    </h6>
  </div>
</template>

<script>
export default {
  name: 'Profile'
}
</script>
